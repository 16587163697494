import Link from "next/link";
import { ReactNode, StyleHTMLAttributes } from "react";

interface Props {
  children: ReactNode;
  href: string;
  style?: StyleHTMLAttributes<any>;
}

const ItemLink = (props: Props) => {
  const { children, href } = props;
  let style = props.style;

  return (
    <Link href={href}>
      <a style={style}>{children}</a>
    </Link>
  );
};

export default ItemLink;
