// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { useState } from "react";
import { ContentItem, ContentItemExtended } from "types/content";
import NavListItem from "./NavListItem";
import useSortableList from "lib/sortableList";
import AddItem from "./listItem/AddItem";
import ConfirmDelete from "./ConfirmDelete";

interface Props {
  navItem: ContentItemExtended;
  onItemClick?: (navItem: ContentItem) => void;
  onItemRemove?: (navItem: ContentItem) => void;
  onNewClick?: (mode: "new" | "existing") => void;
  onReorder?: (ids: string[]) => void;
}

export default function NavItemList(props: Props) {
  const { navItem, onItemClick, onNewClick } = props;
  const [deletingItem, setDeletingItem]: [
    ContentItem | null,
    (v: ContentItem | null) => void
  ] = useState(null as ContentItem | null);

  const sortable = useSortableList({
    onSortComplete,
  });

  function onSortComplete(indices: number[]) {
    const length = indices.length;
    // Remove "Add new item" item
    indices = indices.filter((i) => i !== length - 1);

    const newIds = indices.map(
      (i) => (navItem.children as { id: string }[])[i].id
    );

    props.onReorder && props.onReorder(newIds);
  }

  function onCreateNew() {
    onNewClick && onNewClick("new");
  }

  function onItemRemoveRequested(navItem: ContentItem) {
    setDeletingItem(navItem);
  }

  function onPerformItemRemove() {
    if (props.onItemRemove && deletingItem) {
      if (!deletingItem) {
        throw new Error("Expected `deletingItem`");
      }
      props.onItemRemove(deletingItem);
    }
    setDeletingItem(null);
  }

  function onDeleteClose() {
    setDeletingItem(null);
  }

  return (
    <div className="relative" id="NavItemList">
      {navItem.children.map((navItem) => (
        <NavListItem
          navItem={navItem}
          key={navItem.id}
          onDelete={onItemRemoveRequested}
          sortable={sortable}
          onClick={onItemClick}
        />
      ))}

      <AddItem
        onClick={onCreateNew}
        label="Add nav item"
        className="bg-white"
      />

      <ConfirmDelete
        deleting={!!deletingItem}
        onClose={onDeleteClose}
        onDelete={onPerformItemRemove}
        prompt="Are you sure you want to delete this item?"
      />
    </div>
  );
}
