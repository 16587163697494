// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItemSettings } from "types/content";
import { NavItem } from "types/nav";

export function filterItemsForLoggedInStatus(
  items: NavItem[],
  loggedIn: boolean,
  memberRoles: string[] | undefined
): NavItem[] {
  return items.filter(
    (item) => !item.settings || shouldShow(item, loggedIn, memberRoles)
  );
}

export function shouldShow(
  { settings }: { settings?: ContentItemSettings },
  loggedIn: boolean,
  memberRoles?: string[]
) {
  const {
    isLoggedInVisible,
    isLoggedOutVisible,
    role: itemRole,
  } = settings || {};
  memberRoles = memberRoles && [...memberRoles];

  if (
    itemRole &&
    (!memberRoles ||
      !(memberRoles.includes(itemRole) || memberRoles.includes("super")))
  ) {
    return false;
  }

  if (loggedIn && isLoggedInVisible) {
    return true;
  } else if (!loggedIn && isLoggedOutVisible) {
    return true;
  }

  return false;
}

// Apply hide & show logic and active & inactive logic on the given nav items
export function transformNavItems(
  links: NavItem[],
  loggedIn: boolean,
  roles: string[] | undefined,
  currentPath: string
) {
  const filteredLinks = filterItemsForLoggedInStatus(links, loggedIn, roles);

  return markActive(filteredLinks, currentPath);
}

function markActive(links: NavItem[], currentPath: string): NavItem[] {
  let itemIndex = links.findIndex((item) => item.uri === currentPath);
  if (itemIndex >= 0) {
    return links.map((link, index) => {
      const active = index === itemIndex;
      return { ...link, active };
    });
  } else {
    return markActiveFromInexactMatch(links, currentPath);
  }
}

function markActiveFromInexactMatch(
  links: NavItem[],
  currentPath: string
): NavItem[] {
  let matches = links.filter((link) => currentPath.startsWith(link.uri));

  if (matches.length === 0) {
    return links;
  } else {
    // Find longest match
    const longest = matches.sort(
      (a: NavItem, b: NavItem) => b.uri.length - a.uri.length
    )[0];

    return links.map((link) => {
      const active = link.uri === longest.uri;
      return { ...link, active };
    });
  }
}
