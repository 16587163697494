// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { buildClassName } from "lib/string";
import React from "react";

interface Props {
  allowPdf?: boolean;
  onChange?: (file: File) => void;
  className?: string;
  inputRef?:
    | React.RefObject<HTMLInputElement>
    | ((
        el: HTMLInputElement | null
      ) => null | React.RefObject<HTMLInputElement>)
    | null;
  name?: string;
}

export default function FileInput(props: Props) {
  const { allowPdf, className, name } = props;
  const inputRef = props.inputRef || React.createRef();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    if (!target || !target.files) {
      console.log("No target files");
      return null;
    }
    const file = target.files.length > 0 ? target.files[0] : null;
    if (!file) {
      console.log("No file");
      return null;
    }
    event.preventDefault();
    if (file) {
      const { onChange } = props;
      onChange && onChange(file);
    }
  }

  return (
    <input
      type="file"
      ref={inputRef}
      name={name}
      onChange={onChange}
      accept={`image/*${allowPdf ? ",application/pdf" : ""}`}
      className={buildClassName("FileInput", className)}
    />
  );
}
