import { useContentEditStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
import { ContentItem } from "types/content";
import NavEdit from "./NavEdit";
import NavItemsIndex from "./NavItemsIndex";

interface Props {
  onClose?: () => void;
}

function NavManagement(props: Props) {
  const { onClose } = props;
  const contentEditStore = useContentEditStore();
  const navItem = contentEditStore.currentNavItem();
  const mode = navItem?.baseTypeEditMode || "search";

  function onSearchClose() {
    onClose && onClose();
  }

  function onSearchResultSelected(navItem: ContentItem) {
    if (navItem.plurality === "item") {
      contentEditStore.pushStack({
        baseType: "nav",
        baseTypeEditMode: "edit",
        contentId: navItem.id,
      });
    } else {
      contentEditStore.pushStack({
        baseType: "nav",
        baseTypeEditMode: "list",
        contentId: navItem.id,
      });
    }
  }

  return (
    <div style={{ maxWidth: "500px" }} className="mx-auto">
      {["edit", "add", "list"].includes(mode) && <NavEdit />}
      {mode === "search" && (
        <NavItemsIndex
          onClose={onSearchClose}
          onItemSelected={onSearchResultSelected}
        />
      )}
    </div>
  );
}

export default observer(NavManagement);
