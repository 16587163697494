// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { makeObservable, observable, runInAction } from "mobx";
import * as Sentry from "@sentry/browser";

import { Me, NonMember } from "types/member";
import { getMyself } from "lib/gf-api";
import JwtStore from "stores/JwtStore";
import InvalidSessionError from "lib/gf-api/InvalidSessionError";
import { getNonMember } from "lib/gf-api/member-api";

export default class UserStore {
  baseUrl: string;
  jwtStore: JwtStore;
  fetchedUser: Me | null;
  fetchedNonMember: NonMember | null;
  loading: boolean;
  groupSlug: string;

  constructor(baseUrl: string, jwt: JwtStore, groupSlug: string) {
    this.baseUrl = baseUrl;
    this.jwtStore = jwt;
    this.fetchedUser = null;
    this.fetchedNonMember = null;
    this.loading = false;
    this.groupSlug = groupSlug;

    makeObservable(this, {
      fetchedUser: observable,
      fetchedNonMember: observable,
    });
  }

  async loadUser(): Promise<Me | null> {
    if (this.loading) return null;
    const jwt = this.jwtStore.memberJwt;
    if (!jwt) return null;
    this.loading = true;

    try {
      const user = await getMyself({
        baseUrl: this.baseUrl,
        jwt,
        groupSlug: this.groupSlug,
      });
      runInAction(() => {
        this.fetchedUser = user;
      });
      if (user) {
        Sentry.setUser({
          id: `m-${user.account.id}`,
          groupSlug: this.groupSlug,
        });
      }
    } catch (error) {
      if (error instanceof InvalidSessionError) {
        this.jwtStore.clearJwt();
      }
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return this.fetchedUser;
  }

  get user() {
    const jwt = this.jwtStore.memberJwt;
    if (!jwt && this.fetchedUser) {
      runInAction(() => {
        this.fetchedUser = null;
      });
      return null;
    }
    return this.fetchedUser;
  }

  async loadNonMember() {
    const jwt = this.jwtStore.nonMemberJwt;
    if (!jwt) return null;
    this.loading = true;

    const result = await getNonMember({
      baseUrl: this.baseUrl,
      jwt,
      groupSlug: this.groupSlug,
    });

    if (!result.error) {
      runInAction(() => {
        this.fetchedNonMember = result.data;
        this.loading = false;
      });
      if (result.data) {
        Sentry.setUser({
          id: `nm-${result.data.id}`,
          groupSlug: this.groupSlug,
        });
      }
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }

    return result;
  }

  get nonMember() {
    return this.fetchedNonMember;
  }

  setNonMember(nonMember: NonMember) {
    runInAction(() => {
      this.fetchedNonMember = nonMember;
    });
  }
}
