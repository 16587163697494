/**
 * Displays the "Edit" button on most web pages, to start the content editing flow.
 */
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ADMIN_STYLE } from "lib/Style";
import { useContentEditStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";

interface Props {
  selecting?: boolean;
  onConfirmingHide?: (editing: boolean) => void;
  onSelecting?: (editing: boolean) => void;
}

function GlobalEditControls(props: Props) {
  const { selecting, onConfirmingHide, onSelecting } = props;
  const contentEditStore = useContentEditStore();

  return (
    <div className="GlobalEditControls flex flex-col gap-1 fixed z-20 bottom-4 right-4 md:right-6 m-1 font-montserrat">
      {selecting ? (
        <>
          <button
            className={clsx(
              ADMIN_STYLE.buttonShapeSmall,
              ADMIN_STYLE.buttonActionColors
            )}
            onClick={() => contentEditStore.openModal()}
          >
            Main Menu
          </button>
          <button
            onClick={() => onSelecting && onSelecting(false)}
            className={clsx(
              ADMIN_STYLE.buttonShapeSmall,
              ADMIN_STYLE.buttonBasicColors,
              "bg-white"
            )}
          >
            <XMarkIcon className="w-5 h-5" />
            Dismiss
          </button>
          <button
            onClick={() => onConfirmingHide && onConfirmingHide(false)}
            className={clsx(
              ADMIN_STYLE.buttonShapeSmall,
              ADMIN_STYLE.buttonBasicColors,
              "bg-white"
            )}
          >
            <TrashIcon className="w-5 h-5" />
            Hide
          </button>
        </>
      ) : (
        <button
          onClick={() => onSelecting && onSelecting(true)}
          className={clsx(
            ADMIN_STYLE.buttonShapeSmall,
            ADMIN_STYLE.buttonBasicColors,
            `
              bg-white
              opacity-80
              hover:opacity-100
            `
          )}
        >
          <img
            src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/groupflow-icon.svg"
            className="w-5 h-5"
            alt="GroupFlow Icon"
          />
          <span className="text-secondary-800">Edit Mode</span>
        </button>
      )}
    </div>
  );
}

export default observer(GlobalEditControls);
