// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import fetch from "isomorphic-fetch";
import {
  ApiError,
  ApiResponse,
  RequestError,
  RequestErrorList,
} from "types/api";
import nextConfigFn from "next/config";
import { URI } from "types/web";
import { GroupConfig } from "types/group";

const nextConfig = nextConfigFn();
const { publicRuntimeConfig } = nextConfig;

export function gfApiHeaders({
  jwt,
  groupSlug,
}: {
  jwt?: string | null | undefined;
  groupSlug: string;
}) {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...groupHeaders(groupSlug),
    ...authHeaders(jwt),
  };
}

export function gfGqlHeaders({
  jwt,
  groupSlug,
}: {
  jwt?: string | null | undefined;
  groupSlug: string;
}) {
  return {
    Accept: "application/json",
    "content-type": "application/json",
    ...groupHeaders(groupSlug),
    ...authHeaders(jwt),
  };
}

export function authHeaders(
  jwt?: string | null | undefined
): { Authorization: string } | object {
  return jwt ? { Authorization: `Bearer ${jwt}` } : {};
}

export function groupHeaders(groupSlug: string) {
  return {
    "gf-group": groupSlug,
  };
}

export async function wrapApiResponse<T>(
  response: Response,
  decoder?: (value: any) => T,
  innerProp?: string,
  rootNode?: boolean
): Promise<ApiResponse<T>> {
  const status = httpStatusCategory(response.status);
  decoder = decoder || ((value: any) => value);

  if (status === "ok") {
    let data;
    let meta;
    try {
      data = await decodeRespData(response, decoder, innerProp, rootNode);
    } catch {}

    return {
      error: false,
      data,
      pagination: meta,
    } as ApiResponse<T>;
  } else {
    return handleNonOkResponse(response);
  }
}

export async function wrapTextResponse(
  response: Response
): Promise<ApiResponse<string>> {
  const status = httpStatusCategory(response.status);

  if (status === "ok") {
    let data;
    try {
      data = await response.text();
    } catch {}

    return {
      error: false,
      data,
    } as ApiResponse<string>;
  } else {
    return handleNonOkResponse(response);
  }
}

export async function handleNonOkResponse<T>(response: Response) {
  const status = httpStatusCategory(response.status);

  if (status === "request-error") {
    const body = await response.json();

    return {
      error: true,
      status: response.status,
      requestErrors: body.errors.map((e: any) => ({ title: e.detail })),
    } as ApiResponse<T>;
  } else {
    return {
      error: true,
      status: response.status,
      serverError: true,
    } as ApiResponse<T>;
  }
}

export function wrapGqlResponse<T>(
  response: Response,
  body: any,
  innerProp?: string,
  decoder?: (data: any) => T
): ApiResponse<T> {
  const status = httpStatusCategory(response.status);
  decoder = decoder || ((value: T) => value);
  if (status === "ok") {
    return decodeGqlOkResponse<T>(response, body, innerProp, decoder);
  } else {
    return apiErrorResponse(response, body);
  }
}

export function apiErrorResponse(
  response: Response,
  _body: any
): ApiResponse<any> {
  return {
    error: true,
    serverError: true,
    requestErrors: [],
    status: response.status,
  };
}

export function decodeGqlOkResponse<T>(
  response: Response,
  body: any,
  innerProp?: string,
  decoder?: (data: any) => T
): ApiResponse<T> {
  const gqlError = decodeGqlErrorResponse(response, body);
  if (gqlError) {
    return gqlError;
  }
  const innerData = getInnerData(body, innerProp);
  const data = decoder ? decoder(innerData) : innerData;
  return { error: false, data };
}

export function decodeGqlErrorResponse(
  response: Response,
  body: any
): ApiError | null {
  if (body.errors) {
    let code;
    let message;

    // GraphQL API v1
    if (body && body.errors && Array.isArray(body.errors) && body.errors[0]) {
      const error = body.errors[0];
      if (error.code) {
        code = body.errors[0].code;
      }
      if (error.message) {
        message = body.errors[0].message;
      }
    }

    const requestErrors: RequestError[] = body.errors.map((error: any) => {
      const match = error.message.match(/^(.* has invalid value) .*/);

      if (match) {
        return { title: match[1] };
      } else {
        return { title: error.message };
      }
    });

    const expiredMemberError = body.errors.find(
      (error: any) => error.type === "unauthorized.inactive-member"
    );

    return {
      code,
      error: true,
      memberExpired: !!expiredMemberError,
      message,
      serverError: false,
      requestErrors,
      status: response.status,
    };
  }

  return null;
}

export async function decodeRespData<T>(
  response: Response,
  decoder?: (value: any) => T,
  innerProp?: string,
  rootNode?: boolean
) {
  const body = await response.json();
  const innerData = await getInnerData(body, innerProp, rootNode);
  if (decoder) {
    let decoded;
    try {
      decoded = decoder(innerData) as T;
    } catch (error) {
      console.warn("Failed to decode data", error);
      throw error;
    }

    return decoded;
  } else {
    return innerData as T;
  }
}

export function getInnerData(
  body: any,
  innerProp?: string,
  rootNode?: boolean
) {
  let innerData = rootNode ? body : body.data;
  if (innerProp) {
    innerData = innerData[innerProp];
  }
  return innerData;
}

export function httpStatusCategory(
  status: number
): "ok" | "request-error" | "server-error" | "unexpected-status" {
  if (status >= 400 && status <= 499) {
    return "request-error";
  } else if (status >= 500) {
    return "server-error";
  } else if (status < 200) {
    return "unexpected-status";
  } else if (status >= 300 && status <= 399) {
    return "unexpected-status";
  } else {
    return "ok";
  }
}

export function parseRequestErrors(body: any): RequestErrorList {
  const errors = body.errors.map((err: any) => {
    const { message, ...error } = err;
    return { ...error, detail: err.detail || message } as RequestError;
  });
  return { errors };
}

export async function fetchAndLog(
  url: string,
  options?: any
): Promise<Response> {
  let allOpts = { ...(options || {}), url };
  let { headers } = allOpts;
  if (headers && headers.Authorization) {
    headers = { ...headers, Authorization: "[redacted]" };
    allOpts = { ...allOpts, headers };
  }
  if (publicRuntimeConfig.loggingEnabled) {
    console.log("Core API call to", allOpts);
  }

  try {
    return await fetch(url, options);
  } catch (error) {
    console.warn("Failed to fetch", url, error);
    let message;
    if (error instanceof TypeError) {
      const match = `${error}`.match(/^TypeError: (.*)$/);
      message = match && match[1];
    }
    if (message) {
      throw new OldApiError(message, null, []);
    }
    throw error;
  }
}

export class OldApiError extends Error {
  status: number | null;
  errors: any[];
  constructor(message: string, status: number | null, errors: any[]) {
    super(message); // (1)
    this.status = status;
    this.errors = errors;
  }
}

export function getApiBaseUrl(uri: URI): string {
  return getBackendBaseUrl(uri) + "/api";
}

export function getRootUrl(uri: URI): string {
  const portPart = [80, 443].includes(uri.port) ? "" : `:${uri.port}`;
  return `${uri.proto}://${uri.host}${portPart}`;
}

const ngrokHost = "gf-web.ngrok.io";

export function isNgrokWebHost(host: string) {
  return host === ngrokHost;
}

export function getBackendBaseUrl(uri: URI): string {
  if (uri.host === "localhost") {
    if (publicRuntimeConfig.backendBaseUrl) {
      return publicRuntimeConfig.backendBaseUrl;
    }
    return "http://localhost:4040";
  } else if (uri.host.match(/^\d+\.\d+\.\d+\.\d+$/)) {
    return `http://${uri.host}:4040`;
  } else {
    if (publicRuntimeConfig.backendBaseUrl) {
      return publicRuntimeConfig.backendBaseUrl;
    } else {
      return `${uri.proto}://${uri.host}`;
    }
  }
}

export function getWebSocketBaseUrl(uri: URI): string {
  if (uri.host === "localhost") {
    if (publicRuntimeConfig.backendBaseUrl) {
      const wsBaseUrl = publicRuntimeConfig.backendBaseUrl
        .replace("http://", "ws://")
        .replace("https://", "wss://");
      return `${wsBaseUrl}/socket`;
    }
    return "ws://localhost:4040/socket";
  } else if (uri.host.match(/^\d+\.\d+\.\d+\.\d+$/)) {
    return `ws://${uri.host}:4040/socket`;
  } else {
    if (publicRuntimeConfig.backendBaseUrl) {
      const url = new URL(publicRuntimeConfig.backendBaseUrl);
      const proto = url.protocol === "http" ? "ws" : "wss";
      return `${proto}://${url.host}/socket`;
    } else {
      const proto = uri.proto === "http" ? "ws" : "wss";
      return `${proto}://${uri.host}/socket`;
    }
  }
}

export function getS3EnvBaseUrl(groupConfig: GroupConfig) {
  const bucketUrl = "https://mp1md-pub.s3.us-west-2.amazonaws.com";
  const { deployEnv } = nextConfig.publicRuntimeConfig;
  let path1 = `${bucketUrl}/${deployEnv}`;
  const prefix = publicRuntimeConfig.s3GroupObjectPrefix;
  if (prefix) {
    path1 = `${path1}/${prefix}`;
  }
  return `${path1}/${groupConfig.slug}`;
}

export function paginatedDecoder<T>(decoder: (data: any) => T) {
  return (data: any) => {
    let { entries, ...otherData } = data;
    entries = entries.map(decoder);
    return { entries, ...otherData };
  };
}

export async function downloadDirectFromApi(props: {
  uriPath: string;
  filename: string;
  groupSlug: string;
  jwt: string;
  backendBaseUrl: string;
}) {
  const { backendBaseUrl, uriPath, filename, groupSlug, jwt } = props;
  const endpointUrl = `${backendBaseUrl}/${uriPath}`;
  const headers = {
    ...groupHeaders(groupSlug),
    ...authHeaders(jwt),
  };

  const response = await fetch(endpointUrl, { headers });
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);

  // Generate a link and click it
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
