import Link from "next/link";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

interface Props {
  className?: string;
  href?: string;
  onClick?: () => void;
  label?: string;
}

export default function BackButtonWithLabel(props: Props) {
  const { href, label, onClick } = props;
  const className = clsx("flex items-center gap-4 group", props.className);
  const inner = (
    <>
      <span className="flex items-center justify-center rounded-full bg-white w-10 h-10 translate-x-[4px] group-hover:shadow">
        <ArrowLeftIcon className="w-6 h-6" />
      </span>

      {label && <span className="breadcrumb-text">{label}</span>}
    </>
  );

  if (onClick) {
    return (
      <button onClick={onClick} className={className}>
        {inner}
      </button>
    );
  }

  if (!href) {
    return null;
  }

  return (
    <Link href={href}>
      <a className={className}>{inner}</a>
    </Link>
  );
}
