// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ApiCall, ApiResponse, GraphQlOneRequest } from "types/api";
import {
  AdminGetSectionsParams,
  GetSectionsParams,
  OrdinalParams,
  QuestionForEdit,
  QuestionPurpose,
  Section,
  SectionForEdit,
  SectionParams,
} from "types/field";
import { AdminSignupCountsParams } from "types/signup";
import {
  fetchAndLog,
  getS3EnvBaseUrl,
  gfApiHeaders,
  wrapTextResponse,
} from "./api-util";
import { graphqlMultiCall, graphqlSingleCall } from "./gql";
import { GroupConfig } from "types/group";

export const questionFields = [
  "    component",
  "    id",
  "    ordinal",
  "    purpose",
  "    sectionId",
  "    slug",
  "    specialField",
];

const adminQuestionNames = [
  "    active",
  "    component",
  "    id",
  "    linkToId",
  "    memberVisible",
  "    ordinal",
  "    purpose",
  "    sectionId",
  "    slug",
  "    specialField",
];

const adminSectionNames = [
  "    active",
  "    id",
  "    memberVisible",
  "    name",
  "    ordinal",
  "    purpose",
  "    questions(active: $active, memberVisible: $memberVisible) {",
  `      ${adminQuestionNames.join("\n")}`,
  "    }",
  "    slug",
];

const sectionNames = [
  "    active",
  "    id",
  "    memberVisible",
  "    name",
  "    ordinal",
  "    purpose",
  "    questions {",
  `      ${questionFields.join("\n")}`,
  "    }",
  "    slug",
];

const adminSectionNames2 = [
  "    active",
  "    id",
  "    memberVisible",
  "    name",
  "    ordinal",
  "    purpose",
  "    questions(admin: $admin) {",
  `      ${questionFields.join("\n")}`,
  "    }",
  "    slug",
];

export const answerFields = [
  "arrayValue",
  "id",
  "meta",
  "otherValue",
  "questionId",
  "visible",
];

export async function adminGetQuestionSections(
  call: ApiCall,
  params?: Partial<AdminGetSectionsParams>
): Promise<ApiResponse<SectionForEdit[]>> {
  const req: GraphQlOneRequest = {
    call,
    opName: "AdminGetSections",
    opType: "query",
    query: adminBuildSectionsQuery(params),
  };

  return await graphqlSingleCall<SectionForEdit[]>(req);
}

export function adminBuildSectionsQuery(
  variables?: Partial<AdminGetSectionsParams>
) {
  return {
    argTypes: {
      purpose: "String!",
      active: "Boolean",
      memberVisible: "Boolean",
    },
    returnNames: adminSectionNames,
    variables,
    qName: "adminSections",
  };
}

export function buildSectionsQuery(
  variables?: Partial<GetSectionsParams>,
  opts?: { admin?: boolean }
) {
  return {
    argTypes: {
      purpose: "String!",
      admin: "Boolean",
    },
    returnNames: opts?.admin ? adminSectionNames2 : sectionNames,
    variables,
    qName: "sections",
  };
}

interface GetQuestionsPararms {
  active?: boolean;
  purpose: "signup" | "profile";
}

export async function adminListQuestions(
  call: ApiCall,
  params?: GetQuestionsPararms
): Promise<ApiResponse<QuestionForEdit[]>> {
  return graphqlSingleCall<QuestionForEdit[]>({
    call,
    opName: "AdminGetQuestions",
    opType: "query",
    query: {
      argTypes: {
        purpose: "String!",
        active: "Boolean",
      },
      variables: params || {},
      returnNames: adminQuestionNames,
      qName: "adminQuestions",
    },
  });
}

export async function adminUpsertSection(
  call: ApiCall,
  fnParams: Partial<SectionParams>
): Promise<ApiResponse<SectionForEdit>> {
  let { id, ...params } = fnParams;

  params = encodeQuestions(params);

  return graphqlSingleCall<SectionForEdit>({
    call,
    opName: "AdminUpsertSection",
    opType: "mutation",
    query: {
      argTypes: {
        id: "ID",
        params: "SectionInput",
        admin: "Boolean",
      },
      returnNames: sectionNames,
      qName: "adminUpsertSection",
      variables: { id, params, admin: true },
      decoder: decodeSection,
    },
  });
}

function encodeQuestions(params: any) {
  if (params.questions) {
    const questions = params.questions.map((q: any) => {
      const component = JSON.stringify(q.component);
      return { ...q, component };
    });
    return { ...params, questions };
  }
  return params;
}

export async function startNewSection(
  call: ApiCall,
  {
    sectionParams,
    ordinals,
  }: {
    sectionParams: Partial<SectionParams> & { purpose: QuestionPurpose };
    ordinals: OrdinalParams[];
  }
): Promise<ApiResponse<Section>> {
  sectionParams = encodeQuestions(sectionParams);
  const { purpose } = sectionParams;

  const argTypes = {
    purpose: "String",
    ordinals: "[OrdinalInput]",
    sectionParams: "SectionInput",
  };

  const variables = {
    sectionParams,
    ordinals,
    purpose,
  };

  const result = await graphqlMultiCall<{
    adminUpsertSection: Section;
    listSections: Section[];
    adminReorderSections: Section[];
  }>({
    call,
    argTypes,
    opName: "AdminStartNewSection",
    opType: "mutation",
    queries: [
      {
        argPlaceholders: { params: "sectionParams" },
        qName: "adminUpsertSection",
        returnNames: sectionNames,
        decoder: decodeSection,
      },
      {
        argPlaceholders: { ordinals: "ordinals", purpose: "purpose" },
        qName: "adminReorderSections",
        returnNames: ["id"],
      },
    ],
    variables,
  });

  if (result.error) {
    return result;
  } else {
    return { ...result, data: result.data.adminUpsertSection };
  }
}

export async function adminDeleteSection(
  call: ApiCall,
  variables: { id: string }
): Promise<ApiResponse<any>> {
  return graphqlSingleCall<Section>({
    call,
    opName: "AdminDeleteSection",
    opType: "mutation",
    query: {
      argTypes: { id: "ID" },
      returnNames: ["id"],
      qName: "adminDeleteSection",
      variables,
    },
  });
}

function decodeSection(data: any): Section {
  return data;
}

interface ReorderParams {
  purpose: QuestionPurpose;
  ordinals: OrdinalParams[];
}

export function adminReorderSections(
  call: ApiCall,
  params: ReorderParams
): Promise<ApiResponse<SectionForEdit[]>> {
  return graphqlSingleCall<SectionForEdit[]>({
    call,
    opName: "AdminReorderSections",
    opType: "mutation",
    query: {
      qName: "adminReorderSections",
      argTypes: {
        purpose: "String",
        ordinals: "[OrdinalInput]",
        admin: "Boolean",
      },
      returnNames: sectionNames,
      variables: { ...params, admin: true },
      decoder: (sections) => sections.map(decodeSection),
    },
  });
}

export async function getSignupsReportCsv(
  call: ApiCall,
  params?: Partial<AdminSignupCountsParams>
): Promise<ApiResponse<string>> {
  const json = JSON.stringify(params || {});
  const encodedJson = encodeURIComponent(json);
  const endpointUrl = call.baseUrl + `/question_items?json=${encodedJson}`;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call),
    method: "GET",
  });

  return wrapTextResponse(response);
}

export async function getFormDataFromCloud(groupConfig: GroupConfig) {
  const path = "/forms.json";
  const baseUrl = getS3EnvBaseUrl(groupConfig);
  const endpointUrl = baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {});
  const data = await response.json();
  return {
    error: false,
    data,
    pagination: {},
  };
}
