// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { dayAndDate } from "lib/constants";
import { formatDate } from "lib/date";
import TimeWithOffset from "./TimeWithOffset";

interface Props {
  dateTime: Date | string;
  className?: string;
}

export default function DayDateTimeOffset(props: Props) {
  return (
    <div className={`flex ${props.className ? props.className : ""}`}>
      <span className="mr-4">{formatDate(props.dateTime, dayAndDate)}</span>
      <TimeWithOffset dateTime={props.dateTime} />
    </div>
  );
}
