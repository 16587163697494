import { getFormDataFromCloud } from "lib/gf-api/field-api";
import { makeAutoObservable, runInAction } from "mobx";
import { FormDefinition } from "types/field";
import { GroupConfig } from "types/group";
import { getSectionsAndQuestions } from "lib/forms";

interface Props {
  group: GroupConfig;
}

/**
 * Stores sections and answers data to memory, such that the data can be changed, and components will react.
 */
export default class FormStore {
  group: GroupConfig;
  profile: FormDefinition | null = null;
  profileAdmin: FormDefinition | null = null;
  signup: FormDefinition | null = null;
  signupAdmin: FormDefinition | null = null;

  constructor(props: Props) {
    this.group = props.group;
    makeAutoObservable(this);
  }

  // Load data from cloud
  async loadData() {
    const result = await getFormDataFromCloud(this.group);
    if (!result.error) {
      const profile = getSectionsAndQuestions(result.data, "profile");
      const profileAdmin = getSectionsAndQuestions(result.data, "profile", {
        admin: true,
      });
      const signup = getSectionsAndQuestions(result.data, "signup");
      const signupAdmin = getSectionsAndQuestions(result.data, "signup", {
        admin: true,
      });

      runInAction(() => {
        this.profile = profile;
        this.profileAdmin = profileAdmin;
        this.signup = signup;
        this.signupAdmin = signupAdmin;
      });
    }
  }
}
