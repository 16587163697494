// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import MainContent from "./layout/MainContent";
import LinkButton from "./LinkButton";

export default function ActiveMembershipRequired() {
  return (
    <MainContent>
      <div>
        <h1 className="h1">Expired Membership</h1>
        <p className="p">
          Your membership has expired. Access to this page requires an active
          membership. Please visit the dues page to renew your membership.
        </p>
        <div className="flex justify-center mt-8">
          <LinkButton href="/members/dues">Dues Page</LinkButton>
        </div>
      </div>
    </MainContent>
  );
}
