import clsx from "clsx";
import GroupFlowWideLogo from "components/icon/GroupFlowWideLogo";
import { observer } from "mobx-react-lite";

interface Props {
  className?: string;
  parentTag?: string;
}

const GroupFlowTag = ({ className, parentTag }: Props) => {
  return (
    <a
      className={clsx(parentTag, "GroupFlowTag", className)}
      href="https://www.groupflow.app/"
    >
      <div className="PoweredBy">Powered by</div>

      <span className="LogoWrapper">
        <GroupFlowWideLogo className="Logo" />
      </span>
    </a>
  );
};

export default observer(GroupFlowTag);
