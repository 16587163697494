// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { observer } from "mobx-react-lite";
import GlobalEditControls from "./GlobalEditControls";
import {
  useAppContext,
  useContentEditStore,
  useMember,
} from "lib/gf-app-context";
import { EditStatus } from "types/content";
import { memberHasRoleAccess } from "lib/auth";
import ContentEditModal from "./ContentEditModal";
import { useRouter } from "next/router";
import { useState } from "react";
import SimpleModal from "components/SimpleModal";
import { ADMIN_STYLE } from "lib/Style";
import clsx from "clsx";

/**
 * Starting point for website content editing.
 *
 * This component is called by PageLayout. It displays an "Edit" button on each webpage (with exceptions).
 * When the button is pressed, all the editable parts of the web page become highlighted, and another button,
 * labeled "Main Menu" is displayed above the "Edit" button.
 */
function ContentEditing() {
  const user = useMember();
  const contentEditStore = useContentEditStore();
  const appContext = useAppContext();
  const router = useRouter();
  const [hidden, setHidden] = useState(false);
  const [confirmingHide, setConfirmingHide] = useState(false);

  if (!user) {
    return null;
  }

  const isAdminArea = router.pathname.startsWith("/_admin");

  function setStatus(status: EditStatus) {
    if (status === "selecting") {
      contentEditStore.clearEditing();
      document.body.classList.add("selecting-for-edit");
    } else {
      document.body.classList.remove("selecting-for-edit");
    }
    contentEditStore.setStatus(status);
  }

  function onHidingClose() {
    setHidden(true);
    setConfirmingHide(false);
    setStatus("inactive");
  }

  const contentShowControls = appContext.contentShowControls && !isAdminArea;
  const selecting = contentEditStore.status === "selecting";
  const contentGlobalControlsEnabled =
    contentShowControls && user && memberHasRoleAccess(user.account, "content");

  return (
    <div className="!h-auto ContentEditing">
      <ContentEditModal onRequestClose={() => setStatus("inactive")} />
      {contentGlobalControlsEnabled && (
        <>
          {!hidden && (
            <GlobalEditControls
              selecting={selecting}
              onConfirmingHide={() => setConfirmingHide(true)}
              onSelecting={(selecting) =>
                setStatus(selecting ? "selecting" : "inactive")
              }
            />
          )}
          <SimpleModal
            isOpen={confirmingHide}
            onClose={() => setConfirmingHide(false)}
            themeArea="admin"
          >
            <div className="flex flex-col gap-8">
              <p>
                The "Edit Mode" button will remain hidden until you refresh the
                page.
              </p>
              <div className="flex justify-center align-center gap-4">
                <button
                  onClick={() => setConfirmingHide(false)}
                  className={clsx(
                    ADMIN_STYLE.buttonShapeMedium,
                    ADMIN_STYLE.buttonBasicColors
                  )}
                >
                  Cancel
                </button>
                <button
                  onClick={onHidingClose}
                  className={clsx(
                    ADMIN_STYLE.buttonShapeMedium,
                    ADMIN_STYLE.buttonActionColors
                  )}
                >
                  Hide
                </button>
              </div>
            </div>
          </SimpleModal>
        </>
      )}
    </div>
  );
}

export default observer(ContentEditing);
