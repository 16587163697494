// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { NextPageContext } from "next";
import { parseCookies } from "nookies";
import { cookieName } from "lib/constants";
import nextConfigFn from "next/config";
import { NextIncomingMessage } from "next/dist/server/request-meta";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { encodeQueryString } from "./utils/url";
import { useLogin } from "./auth";
import { useMemberJwt } from "./gf-app-context";

const config = nextConfigFn();
const { jwtVersion } = config.publicRuntimeConfig;

export function decodeJWT(jwt: string) {
  const [, bodyEncoded] = jwt.split(".");
  return (bodyEncoded && JSON.parse(atob(bodyEncoded))) ?? null;
}

export function jwtIsValid(jwt: string | undefined) {
  if (!jwt) return false;
  const claims = decodeJWT(jwt);
  return claimsAreValid(claims);
}

export function claimsAreValid(claims: any) {
  return (claims && !isExpired(claims) && claims.ver === jwtVersion) || false;
}

export function isExpired(claims: any) {
  return claims && expiresAt(claims) < new Date();
}

export function expiresAt({ exp }: { exp: number }) {
  return new Date(exp * 1000);
}

function atob(str: string) {
  return Buffer.from(str, "base64").toString("binary");
}

export function getServerSideJWT(
  req: NextIncomingMessage | undefined
): string | undefined {
  const cookies = parseCookies({ req } as NextPageContext);
  return cookies[cookieName];
}

// Looks for a `jwt` parameter in the current URL, and logs in with it.
export function useAutoLogin(props?: Partial<{ loginRequired: boolean }>) {
  const router = useRouter();
  const jwtInQuery = router.query.jwt as string | undefined;
  const { login } = useLogin();
  const jwt = useMemberJwt();

  useEffect(() => {
    if (jwtInQuery) {
      const { jwt: otherJwt, id, ...newParams } = router.query;
      const queryString =
        Object.keys(newParams).length > 0
          ? `?${encodeQueryString(newParams)}`
          : "";
      let newLocation = window.location.toString().replace(/\?.*$/, "");
      newLocation = `${newLocation}${queryString}`;
      login(jwtInQuery);
      window.location.replace(newLocation);
    } else if (props?.loginRequired && !jwt) {
      router.push(`/login?ret=${encodeURIComponent(router.asPath)}`);
    }
  }, [jwtInQuery]);

  return jwtInQuery;
}
