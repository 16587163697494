// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { Me, Member, MemberItem, NotificationMethod } from "types/member";
import { SearchFilter } from "types/ui";

export const SubscriptionFormFields = [
  { name: "groupAnnouncements", label: "Group-wide announcements" },
  {
    name: "memberMessages",
    label: "Notification of a new chat message to you",
  },
  { name: "eventAnnouncements", label: "Events: New event announcement" },
  {
    name: "eventReminders",
    label: "Events: Weekly 'Last chance' reminder of upcoming events",
  },
  { name: "rsvpConfirmed", label: "Events: Registration confirmation receipt" },
  {
    name: "eventComments",
    label:
      "Events: Notification of new messages from an event's discussion thread, for an event you are going to",
  },
  {
    name: "rsvpReminders",
    label:
      "Events: Reminder of an event tomorrow you have RSVPd 'yes' or 'maybe' to",
  },
  {
    name: "newRsvps",
    label:
      "Events: Hosts only: Notification of new RSVPs to an event you're hosting",
  },
];

export const SubscriptionDefaultValues = SubscriptionFormFields.reduce(
  (obj, field) => ({ ...obj, [field.name]: ["email"] as NotificationMethod[] }),
  {} as { [index: string]: NotificationMethod[] }
);

export function memberToMemberItem(member: Member): MemberItem {
  const { profile, ...memberItemValues } = member;

  return {
    ...memberItemValues,
    intro: "",
    location: "",
    name: "",
    title: "",
    photo: null,
    pronouns: [],
    ...profile,
    memberMeetupId: null,
  };
}

export const MemberRoles = [
  { value: "admin", label: "Admin Site" },
  { value: "content", label: "Content Management" },
  { value: "elections", label: "Elections" },
  { value: "events", label: "Events" },
  { value: "group_announcements", label: "Group Announcements" },
  { value: "members", label: "Members Management" },
  { value: "super", label: "Superuser" },
];

export const SpecialFields = [
  { value: "intro", label: "Introduction", purpose: "profile" },
  { value: "location", label: "Location", purpose: "profile" },
  { value: "meetup", label: "Meetup Question", purpose: "profile" },
  { value: "name", label: "Name", purpose: "profile" },
  { value: "photo", label: "Photo", purpose: "profile" },
  { value: "pronouns", label: "Pronouns", purpose: "profile" },
];

export function memberIsActive(member: Me | undefined | null) {
  if (!member) return false;
  const { account } = member;
  return account.isActive;
}

export const MEMBERS_LIST_ADMIN_FILTERS: SearchFilter[] = [
  {
    defaultLabel: "Account Status",
    name: "active",
    options: [
      {
        label: "Account: Inactive",
        value: "inactive",
        apiParams: { active: { eq: false } },
      },
      {
        label: "Account: Active",
        value: "active",
        apiParams: { active: { eq: true } },
      },
    ],
    allowReset: true,
  },
  {
    defaultLabel: "Dues",
    name: "dues",
    options: [
      {
        label: "Dues Exempt",
        value: "dues-exempt",
        apiParams: { duesExempt: { eq: true } },
      },
      {
        label: "Non-Exempt",
        value: "non-exempt",
        apiParams: { duesExempt: { eq: false } },
      },
    ],
    allowReset: true,
  },
  {
    defaultLabel: "Banned",
    name: "banned",
    options: [
      { label: "Banned", value: "banned", apiParams: { banned: { eq: true } } },
      {
        label: "Not Banned",
        value: "not-banned",
        apiParams: { banned: { eq: false } },
      },
    ],
    allowReset: true,
  },
  {
    defaultLabel: "Sort: Recently Joined",
    name: "sort",
    options: [
      { label: "Name", value: "name", apiParams: {} },
      { label: "Recently Joined", value: "recently-joined", apiParams: {} },
    ],
  },
];
