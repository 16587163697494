/**
 * Page sections for a web page, including Header, Secondary Nav, Main, and Footer.
 *
 * This is usually called from PageLayout.
 * If `path` is provided, it will render the page at that path.
 */

import SecondaryNavbar from "components/layout/SecondaryNavbar";
import { buildSiteContext } from "components/webComponent";
import { useGroupConfig } from "lib/gf-app-context";
import { resolveRoute } from "lib/web-component";
import Head from "next/head";
import { ReactNode, useEffect, useState } from "react";

interface Props {
  cacheVersion?: number;
  children?: ReactNode;
  loggedIn?: boolean;
  path?: string;
}

const context = buildSiteContext();

export default function PageSections(props: Props) {
  const [siteExports, setSiteExports] = useState<any>();
  const { initializeWebSite } = siteExports || {};
  initializeWebSite && initializeWebSite(context);

  const group = useGroupConfig();
  const { webSiteAssets } = group;
  const { cssUrl } = webSiteAssets || {};

  function getSiteExports() {
    // groupFlowExports are defined in a <script> tag added in pages/_document.tsx
    const gfExports = (window as any).groupFlowExports;
    setSiteExports(gfExports);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      getSiteExports();
    }
  }, [props.cacheVersion]);

  return (
    <>
      <Head>{cssUrl && <link rel="stylesheet" href={cssUrl} />}</Head>
      {page({
        path: props.path,
        children: props.children,
        loggedIn: props.loggedIn,
        siteExports,
      })}
    </>
  );
}

function page({
  path,
  children,
  loggedIn,
  siteExports,
}: {
  path: string | undefined;
  children: ReactNode | undefined;
  loggedIn: boolean | undefined;
  siteExports: any;
}) {
  let Page;
  const { Components, ComponentsLookup, ROUTES } = siteExports || {};

  // Convert ROUTES to an array of objects, with the `route` property added.
  const routes = Object.entries(ROUTES || {}).map(
    ([route, info]: [string, any]) => ({ ...info, route })
  );

  if (!ROUTES || !Components || !ComponentsLookup) {
    return null;
  }

  const routedInfo = path && resolveRoute(path, routes);

  if (
    routedInfo &&
    routedInfo.route !== "/members/*" &&
    routedInfo.route !== "/_admin/*"
  ) {
    Page = Components[routedInfo.name];

    return Page && <Page context={context} children={children} />;
  } else if (path?.startsWith("/_admin")) {
    return children;
  } else if (
    loggedIn &&
    (path?.startsWith("/members") || path?.startsWith("/events"))
  ) {
    const headerName = ComponentsLookup["members-area-header"];
    const footerName = ComponentsLookup["default-footer"];
    let Header = (headerName && Components[headerName]) || null;
    let Footer = (footerName && Components[footerName]) || null;
    let secondaryNavBar: ReactNode = <SecondaryNavbar className="py-4" />;

    return (
      <>
        {Header && <Header />}
        {secondaryNavBar}
        {children}
        {Footer && <Footer />}
      </>
    );
  } else {
    let headerName = ComponentsLookup["default-header"];
    const footerName = ComponentsLookup["default-footer"];
    let Header = (headerName && Components[headerName]) || null;
    let Footer = (footerName && Components[footerName]) || null;
    let secondaryNavBar: ReactNode = <SecondaryNavbar className="py-4" />;

    return (
      <>
        {Header && <Header />}
        {secondaryNavBar}
        {children}
        {Footer && <Footer />}
      </>
    );
  }
}
