import ProgressIndicator from "components/ProgressIndicator";
import { SyntheticEvent } from "react";
import ActionButton from "./ActionButton";
import HollowButton from "./HollowButton";
import clsx from "clsx";

interface Props {
  buttonsClass?: string;
  cancel?: { onClick: () => void; disabled?: boolean };
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  marginTopClass?: string;
  saving?: boolean;
  submitButton?: Partial<{
    handleClick: (e: SyntheticEvent) => void;
    label?: string;
    type: "button" | "submit";
    widthClass?: string;
  }>;
}

export default function SubmitButtonRow(props: Props) {
  const { cancel, children, className, disabled, saving, submitButton } = props;
  const marginTopClass = props.marginTopClass ?? "mt-4";
  const buttonsClass = clsx("font-semibold", props.buttonsClass);

  return (
    <div
      className={clsx(
        "flex gap-4 justify-between relative",
        marginTopClass,
        className
      )}
    >
      {cancel ? (
        <HollowButton
          onClick={() => cancel.onClick()}
          className={buttonsClass}
          disabled={cancel.disabled}
        >
          Cancel
        </HollowButton>
      ) : (
        <div />
      )}
      {saving && <ProgressIndicator />}
      {!saving && (
        <ActionButton
          type={submitButton?.type || "submit"}
          disabled={disabled || false}
          onClick={submitButton?.handleClick}
          className={buttonsClass}
          widthClass={submitButton?.widthClass}
          size="large"
        >
          {submitButton?.label || children || "Save"}
        </ActionButton>
      )}
    </div>
  );
}
