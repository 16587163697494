import clsx from "clsx";
import { SyntheticEvent } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledClass?: string;
  hoverClass?: string;
  onClick?: (e: SyntheticEvent) => void;
  opacityClass?: string;
  size?: "medium" | "large";
  type?: "button" | "submit";
  widthClass?: string;
}

export default function ActionButton(props: Props) {
  const { children, className, disabled, onClick, type, widthClass } = props;
  const heightClass =
    props.type === "submit" || props.size === "large" ? "h-12" : "h-9";
  const paddingX = props.type === "submit" ? "px-6" : "px-5";
  const hoverClass = disabled
    ? undefined
    : props.hoverClass || "hover:saturate-150 hover:brightness-125";
  const backgroundClass = disabled
    ? props.disabledClass || "bg-gray-300 cursor-not-allowed"
    : "bg-gradient-to-r from-new-green-500 to-new-green-600";
  const opacityClass = disabled ? undefined : props.opacityClass;

  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        widthClass,
        heightClass,
        paddingX,
        "rounded-full",
        backgroundClass,
        opacityClass,
        hoverClass,
        "text-white",
        "font-medium",
        "transition",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
