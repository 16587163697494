import clsx from "clsx";
import { CloseButton } from "components/ui";
import Style, { ADMIN_STYLE } from "lib/Style";
import { useThemeInfo } from "lib/theme";
import { useRouter } from "next/router";
import { useEffect } from "react";
import ReactModal from "react-modal";
import { ThemeArea } from "types/theme";

interface Props {
  children: React.ReactNode;
  className?: string;
  floatCloseButton?: boolean;
  isOpen?: boolean;
  onAfterClose?: () => void;
  onClose?: () => void;
  contentStyle?: any;
  modalStyle?: any;
  modalContentStyle?: any;
  themeArea?: ThemeArea;
  withBodyStyle?: boolean;
}

export default function SimpleModal(props: Props) {
  let {
    children,
    className,
    isOpen,
    modalStyle: modalStyle2,
    modalContentStyle,
    onAfterClose,
    onClose,
  } = props;
  const withBodyStyle = props.withBodyStyle ?? true;
  const floatCloseButton = props.floatCloseButton ?? true;
  const { themeArea: defaultThemeArea } = useThemeInfo();
  const themeArea = props.themeArea || defaultThemeArea;
  let modalStyle =
    themeArea === "admin" ? ADMIN_STYLE.modalStyle : Style.modalStyle;
  modalStyle = {
    ...modalStyle,
    ...(modalStyle2 || {}),
    content: {
      ...modalStyle.content,
      ...(modalContentStyle || {}),
      ...(props.contentStyle || {}),
      ...(modalStyle2?.content || {}),
    },
  };
  const router = useRouter();

  if (themeArea === "admin") {
    className = clsx("gf-area-admin bg-gf-green-ultralight", className);
  } else {
    className = clsx("gf-customer gf-area-members bg-white", className);
  }

  let bodyClass = "";
  if (withBodyStyle && themeArea === "admin") {
    bodyClass = ADMIN_STYLE.modalBodyStyle;
  } else if (withBodyStyle) {
    bodyClass = Style.modalBodyStyle;
  }

  useEffect(() => {
    const handleRouteChange = () => {
      onClose && onClose();
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  return (
    <ReactModal
      isOpen={isOpen === undefined ? true : isOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onClose}
      style={modalStyle}
      className={className}
    >
      <>
        <div
          className={clsx(
            "CloseButton flex justify-end",
            floatCloseButton ? "float-right mt-3 mr-3" : ""
          )}
        >
          <CloseButton onClick={onClose} />
        </div>

        <div className={bodyClass}>{children}</div>
      </>
    </ReactModal>
  );
}
